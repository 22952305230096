import { versionName } from './version';

export const environment = {
  production: false,
  hmr: false,
  qa: true,
  COMPANY_ID: 0,
  version: versionName,
  build: 7,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
    forceSubdomain: '@afiliates/force-subdomain',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/dashboard',
    redirectToWhenUnauthenticated: '/auth/login',
    maxVideoSizeInMb: 200,
    autoRefreshInterval: 10000,
  },
  api: {
    baseUrl: 'https://api-qas.clube-afiliados.ligafacens.com',
    auth: {
      login: '/auth/local',
    },
    users: {
      list: '/users',
      get: '/users/{userId}',
      create: '/users',
      update: '/users/{userId}',
      delete: '/users/{userId}',
      me: '/users/me',
    },
    userPassword: {
      sendForgotPasswordCodeToEmail: '/users-password/forgot/email/{email}/domain/{domain}',
      validateForgotPasswordCode: '/users-password/isValidResetPasswordCode',
      resetPassword: '/users-password/reset/{resetPasswordCode}',
      updateUserPassword: '/users-password/password/change/domain/{domain}',
    },
    companies: {
      list: '/companies?join=owner',
      get: '/companies/{companyId}?join=owner',
      create: '/companies',
      update: '/companies/{companyId}',
      delete: '/companies/{companyId}',
    },
    banners: {
      list: '/banners',
      get: '/banners/{bannerId}',
      create: '/banners',
      update: '/banners/{bannerId}',
      delete: '/banners/{bannerId}',
    },
    categories: {
      list: '/categories',
      create: '/categories',
      listPaginated: '/categories?page={page}&limit={limit}',
      listPaginatedWithParams: '/categories?s={searchParams}&page={page}&limit={limit}',
      get: '/categories/{categoryId}',
      update: '/categories/{categoryId}',
    },
    sales: {
      list: '/users-products-sales',
      get: '/users-products-sales/{saleId}',
      update: '/users-products-sales/{saleId}',
      create: '/users-products-sales',
      finish: '/users-products-sales/{saleId}/finish',
      cancel: '/users-products-sales/{saleId}/cancel',
      reports: '/users-products-sales/report',
    },
    sectors: {
      list: '/sectors',
      get: '/sectors/{sectorId}',
      create: '/sectors',
      update: '/sectors/{sectorId}',
      delete: '/sectors/{sectorId}',
    },
    leads: {
      list: '/leads',
      get: '/leads/{leadId}',
      update: '/leads/{leadId}',
    },
    tags: {
      list: '/tags',
      get: '/tags/{tagId}',
      create: '/tags',
      update: '/tags/{tagId}',
      delete: '/tags/{tagId}',
    },
    productTags: {
      list: '/products-tags',
      get: '/products-tags/{productTagId}',
      create: '/products-tags',
      update: '/products-tags/{productTagId}',
      delete: '/products-tags/{productTagId}',
    },
    productsServices: {
      list: '/products',
      get: '/products/{productId}',
      create: '/products',
      update: '/products/{productId}',
    },
    productsMedias: {
      list: '/products-medias',
      get: '/products-medias/{productMediaId}',
      create: '/products-medias',
      update: '/products-medias/{productMediaId}',
      delete: '/products-medias/{productMediaId}',
    },
    divulgationPages: {
      listWithParams: '/divulgations-pages?s={searchParams}&join=sectionOne&join=sectionTwo&join=sectionThree&join=sectionFour',
      get: '/divulgations-pages/{divulgationPageId}&join=sectionOne&join=sectionTwo&join=sectionThree&join=sectionFour',
      create: '/divulgations-pages',
      update: '/divulgations-pages/{divulgationPageId}',
    },
  },
  errorCodes: {
    foreignKeyConstraint: '23503',
  },
};
