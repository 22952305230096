//#region Imports

import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SeoService } from './@core/utils/seo.service';

//#endregion

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private seoService: SeoService,
  ) {
    this.window = this.document.defaultView;
  }

  //#endregion

  //#region public properties

  public favIcon!: HTMLLinkElement | null;

  //#endregion

  //#region private properties

  private darkIcon: string = '/assets/imgs/brow_favicon_dark.svg';

  private window: Window | null;

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.seoService.trackCanonicalChanges();

    this.favIcon = document.querySelector('#appIcon');

    if (this.window!.matchMedia && this.window!.matchMedia('(prefers-color-scheme: dark)').matches)
      this.favIcon!.href = this.darkIcon;
  }

  //#endregion

}
